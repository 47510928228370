<template>
  <div> 
    <div v-if="budgetDataLoaded && budgetAvailable" class="remaining_budget d-grid">
      <span class="mr-1">{{$t('loyalty.Remaining_budget')}}:</span><br/>
      <span class="d-flex align-items-center">
        {{remainingBudget}} {{remainingBudget !== 1 ? $t('common.coins') : $t('common.coin')}}
        <i @click="explanationModal = true;" class="fas fa-info-circle ml-1 pointer"/>
      </span>    
    </div>

    <b-modal class="pl-0 pr-0" :can-cancel="['x']" :active.sync="explanationModal" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <span>{{$t('loyalty.Remaining_budget_for_this_month')}}</span>
        </CCardHeader>        
        <CCardBody>
          <span>{{$t('loyalty.Budget_explanation')}}</span>
        </CCardBody>
        <CCardFooter>          
          <CButton color="secondary" @click="explanationModal = false"><i class="fas fa-times mr-1"/>{{$t('close')}}</CButton>
        </CCardFooter>        
      </CCard>
    </b-modal>    
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'remainingBudget',
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      budgetData: null,
      budgetDataLoaded: false,
      budgetAvailable: false,
      remainingBudget: 0,
      explanationModal: false
    }
  },
  methods: {
    getManagerBudget() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/budget')
      .then(res => {
        this.budgetData = res.data.data;
        // Check if the budgetData is available
        if(this.budgetData) {
          // Update the budgetAvailable value
          this.budgetAvailable = true;
          // Set the remainingBudget value
          this.remainingBudget = (this.budgetData.assigned_budget - this.budgetData.used_budget);
        }
        // Update the budgetDataLoaded value
        this.budgetDataLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    } 
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    this.getManagerBudget();
  }
}
</script>

<style scoped>
  .remaining_budget img {
    height: 25px;
  }
</style>
