<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>
            <span class="sidebar_subject">
              {{$t('sm.New_spark_template')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>    
          </CCol>      
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="text" :label="$t('sm.Spark_template_name')" class="mb-0" v-model="moment.name" required was-validated/>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="text" :label="$t('sm.Spark_template_tag')" class="mb-0" v-model="moment.tag" required was-validated/>
          </CCol>
          <CCol cols="12" lg="12" class="pt-0">
            <CTextarea @input="countdown()" rows="2" maxlength="200" :label="$t('sm.Spark_template_description')" class="mt-0 mb-1" v-model="moment.description"/>
            <span class="countdown float-none" v-if="remainingCount.description > 0">{{remainingCount.description}} {{$t('common.characters_remaining')}}</span>
          </CCol>                   
        </CRow>
        <!-- Language -->
        <CRow class="m-0 dropdown language">
          <CCol cols="12" lg="12" class="pt-0">
            <span><b>{{$t('common.Language')}}</b></span>            
          </CCol>         
          <CCol cols="12" lg="12" class="pt-0">
            <multiselect class="data_table"
                          v-model="moment.template_language" 
                          :options="languages"
                          :multiple="false"
                          :hide-selected="true"
                          :close-on-select="true"
                          :searchable="false"
                          track-by="platform_language_id_external" 
                          label="name"
                          :show-labels="false">

              <template slot="singleLabel" slot-scope="language">
                <span class="language_flag" v-html="getTwemoji(language.option.flag)"></span>
                <span class="language_name">{{ language.option.name }}</span>
              </template>

              <template slot="option" slot-scope="language">
                <span class="language_flag" v-html="getTwemoji(language.option.flag)"></span>
                <span class="language_name">{{ language.option.name }}</span>
              </template>
            </multiselect>              
          </CCol>
        </CRow>        
        <!-- Email -->
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <span><b>{{$t('common.Email')}}</b></span>            
          </CCol>         
          <CCol cols="12" lg="12" class="pt-0">
            <CInput type="text" :label="$t('common.Subject')" class="mb-0" v-model="moment.subject" required was-validated/>
          </CCol>           
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('communications.Email_content_tags')}}</label>
            <div class="meta mb-2">
              <span class="mr-1">{{$t('communications.Tags_currently_supported')}}</span>
              <span v-if="productTag === 'loyalty'">{$platform_name}, {$email_signatory}, {$name}, {$points}</span>
              <span @click="copyToClipboard(productTag);" class="pointer ml-1">
                <i class="fas fa-copy"/>
              </span>
            </div>
            <CInput type="text" v-model="moment.email_content_tags" required was-validated class="mb-0">
            </CInput>
          </CCol>          
        </CRow>      
        <!-- Loyalty points -->
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <span><b>{{$t('common.Coins')}}</b></span>            
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <CInput type="number" v-model="moment.points" :label="$t('sm.Coints_received_for_spark')" class="mb-0"/>
          </CCol>
          <!-- <CCol cols="12" lg="12" class="pt-0">
            <CInput @input="countdown()" type="text" maxlength="50" :label="$t('communications.Points_title')" class="mt-0 mb-1" v-model="moment.points_title"></CInput>
            <span class="countdown float-none" v-if="remainingCount.points_title > 0">{{remainingCount.points_title}} {{$t('common.characters_remaining')}}</span>
          </CCol>
          <CCol cols="12" lg="12" class="pt-0">
            <CTextarea @input="countdown()" rows="2" maxlength="200" :label="$t('communications.Points_description')" class="mt-0 mb-1" v-model="moment.points_description"/>
            <span class="countdown float-none" v-if="remainingCount.points_description > 0">{{remainingCount.points_description}} {{$t('common.characters_remaining')}}</span>
          </CCol> -->
        </CRow>
        <!-- Push notifications -->
        <!-- <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <span><b>{{$t('communications.Push_notifications')}}</b></span>            
          </CCol>
          <CCol cols="4" lg="4" class="pt-0">
            <CInput @input="countdown()" type="text" maxlength="20" :label="$t('communications.Push_title')" class="mt-0 mb-1" v-model="moment.push_title"></CInput>
            <span class="countdown float-none" v-if="remainingCount.push_title > 0">{{remainingCount.push_title}} {{$t('common.characters_remaining')}}</span>
          </CCol>
          <CCol cols="8" lg="8" class="pt-0">
            <CInput @input="countdown()" type="text" maxlength="60" :label="$t('communications.Push_message')" class="mt-0 mb-1" v-model="moment.push_message"/>
            <span class="countdown float-none" v-if="remainingCount.push_message > 0">{{remainingCount.push_message}} {{$t('common.characters_remaining')}}</span>
          </CCol>
        </CRow> -->
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div>
              <CButton color="primary" @click="insertMomentTemplate();"><i class="fas fa-check mr-1"/>{{ $t('Save') }}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'momentTemplateDetails',
  props: ['history', 'productTag'],
  components: {
    Multiselect,
    QuickEdit
  },
  data() {
    return {
      moment: {},
      languages: [],
      maxCount: {
        description: 200,
        points_title: 50,
        points_description: 200,
        push_title: 20,
        push_message: 60
      },
      remainingCount: {
        description: 200,
        points_description: 200,
        points_title: 50,
        push_title: 20,
        push_message: 60
      }      
    }
  },
  methods: {
    insertMomentTemplate() {
      let params = {};
      params.momentData = this.moment;

      if(this.validateMomentTemplate(params) === true) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-template', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('sm.Spark_template_added'), type: 'is-success', duration: 3000 });
          // Emit the loyalty_moment_added event
          this.$bus.$emit('loyalty_moment_added');
          // Reset the data
          this.resetMomentData();
          // Close the sidebar
          this.closeSidebarRight();          
        })
        .catch(err => {
          if(err.response.data.code === 'Doublure Moment Tag') {
            this.$buefy.toast.open({ message: this.$t('sm.Spark_tag_already_exists'), type: 'is-danger', duration: 2000 });
          } else {                    
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          }
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    validateMomentTemplate(params) {
      let name = params.momentData.name;
      let tag = params.momentData.tag;              

      // Check if base information for the Moment template is available
      if(name && tag) {
        return true;
      } else {
        return false;
      }
    },    
    resetMomentData() {
      this.moment = {
        name: null,
        description: null,
        tag: null,
        email_content_tags: '{$platform_name}, {$email_signatory}, {$name}, {$points}',
        subject: null,
        json: null,
        html: null,        
        points: null,
        points_title: null,
        points_description: null,
        push_title: null,
        push_message: null,
        template_language: null
      };
    },
    getPlatformLanguages() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/common/languages')
      .then(res => {      
        this.languages = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    countdown() {
      // Calculate remaining description length
      if(this.moment.description !== null) this.remainingCount.description = this.maxCount.description - this.moment.description.length;
      // Calculate remaining points title length
      if(this.moment.points_title !== null) this.remainingCount.points_title = this.maxCount.points_title - this.moment.points_title.length;
      // Calculate remaining points description length
      if(this.moment.points_description !== null) this.remainingCount.points_description = this.maxCount.points_description - this.moment.points_description.length;
      // Calculate remaining push title length
      if(this.moment.push_title !== null) this.remainingCount.push_title = this.maxCount.push_title - this.moment.push_title.length;
      // Calculate remaining push message length
      if(this.moment.push_message !== null) this.remainingCount.push_message = this.maxCount.push_message - this.moment.push_message.length;
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },     
    copyToClipboard(productTag) {
      let copyValue = "";

      if(productTag === 'loyalty') copyValue = "{$platform_name}, {$email_signatory}, {$name}, {$points}";
      
      // Create temporary textarea for copy
      var copy = document.createElement("textarea");
      document.body.appendChild(copy);
      copy.value = copyValue;
      copy.select();
      document.execCommand("copy");
      document.body.removeChild(copy);
    }
  },
  mounted: function() {
    this.resetMomentData();
    this.getPlatformLanguages();
  }  
}
</script>