<template>
  <div class="w-100 dropdown language">
    <div class="w-100 locale-changer">
      <multiselect class="data_table"
                    v-model="selectedLanguage" 
                    :options="languages" 
                    :multiple="false"
                    :hide-selected="true"
                    :close-on-select="true"
                    :searchable="false"
                    track-by="code" 
                    label="name"
                    :show-labels="false"
                    @input="updateLanguage();">

        <template slot="singleLabel" slot-scope="language">
          <span class="language_flag" v-html="getTwemoji(language.option.flag)"></span>
          <span class="language_name">{{ language.option.name }}</span>
        </template>

        <template slot="option" slot-scope="language">
          <span class="language_flag" v-html="getTwemoji(language.option.flag)"></span>
          <span class="language_name">{{ language.option.name }}</span>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'TheHeaderLanguage',
  components: {
    Multiselect
  },
  data () {
    return {
      selectedLanguage: null,
      languages: [
        {code: 'en-EN', flag: '🇬🇧', name: 'English'},
        {code: 'nl-NL', flag: '🇳🇱', name: 'Nederlands'},
        {code: 'de-DE', flag: '🇩🇪', name: 'Deutsch'},
        {code: 'es-ES', flag: '🇪🇸', name: 'Español'},
        {code: 'fr-FR', flag: '🇫🇷', name: 'Français'},
        {code: 'zh-CN', flag: '🇨🇳', name: '中国人'},
        {code: 'pl-PL', flag: '🇵🇱', name: 'Polsku'},
        {code: 'cs-CZ', flag: '🇨🇿', name: 'Česky'},
        {code: 'bg-BG', flag: '🇧🇬', name: 'Бългapcки eзик'},
        {code: 'da-DK', flag: '🇩🇰', name: 'Dansk'},
        {code: 'et-EE', flag: '🇪🇪', name: 'Eesti'},
        {code: 'fi-FI', flag: '🇫🇮', name: 'Suomalainen'},
        {code: 'el-GR', flag: '🇬🇷', name: 'ελληνικά'},
        {code: 'hu-HU', flag: '🇭🇺', name: 'Magyarul'},
        {code: 'id-ID', flag: '🇮🇩', name: 'Indonesia'},
        {code: 'it-IT', flag: '🇮🇹', name: 'Italiano'},
        {code: 'ja-JP', flag: '🇯🇵', name: '日本語'},
        {code: 'lv-LV', flag: '🇱🇻', name: 'Latviski'},
        {code: 'lt-LT', flag: '🇱🇹', name: 'Lietuviškai'},
        {code: 'pt-PT', flag: '🇵🇹', name: 'Português'},
        {code: 'ro-RO', flag: '🇷🇴', name: 'Romaneste'},
        {code: 'ru-RU', flag: '🇷🇺', name: 'Pусском'},
        {code: 'sk-SK', flag: '🇸🇰', name: 'Slovensky'},
        {code: 'sl-SI', flag: '🇸🇮', name: 'Slovensko'},
        {code: 'sv-SE', flag: '🇸🇪', name: 'Svenska'},
        {code: 'tr-TR', flag: '🇹🇷', name: 'Türkçe'},
        {code: 'uk-UA', flag: '🇺🇦', name: 'Yкраїнською'},
      ],
    }
  },
  methods: {
    updateLanguage() {
      setTimeout(function(){
        this.$i18n.locale = this.selectedLanguage.code;

        localStorage.setItem('userPlatformLanguage', this.$i18n.locale);
        this.$bus.$emit('language_changed');
      }.bind(this), 100);
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }   
  },
  mounted: function() {
    let languageCode = null;
    // Set the languageCode value based on local storage of env value
    (localStorage.getItem('userPlatformLanguage') !== null) ? languageCode = localStorage.getItem('userPlatformLanguage') : languageCode = process.env.VUE_APP_I18N_LOCALE;    
    // Get the array index of the selected language
    let languageIndex = this.languages.findIndex(obj => obj.code === languageCode);
    // Set the selectedLanguage value
    this.selectedLanguage = this.languages[languageIndex];
  }
}
</script>