<template>
  <div>
    <div class="sidebar_container moment_sidebar">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{$t('sm.Edit_smart_spark')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="4" xl="4" lg="5" md="6" sm="12" xs="12" class="pt-0">
            <div ref="momentContainer" class="moment_container">
              <div class="moment_content" :class="{ 'flipped': isFlipped }">
                <div class="front" @click.stop="toggleFlip">                    
                  <div ref="momentCard" class="moment_card moment_card_html" v-html="moment.html" v-show="isContentLoaded"/>                  
                </div>
                <div class="back d-flex flex-column" @click.stop="toggleFlip">
                  <div class="w-100">
                    <CRow class="w-100 m-0">
                      <CCol cols="12" lg="12" md="12" sm="12">
                        <div class="d-flex">
                          <div class="flex-grow-1 moment_date">
                            <span v-if="moment.scheduled_for">{{moment.scheduled_for | moment("DD-MM-YYYY")}}</span>
                            <span v-else>{{new Date() | moment("DD-MM-YYYY")}}</span>
                          </div>
                          <div class="stamp d-flex flex-grow-1 justify-content-end">
                            <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/logo/harryhr_stamp.png/' + clientToken"/>
                          </div>
                        </div>
                      </CCol>             
                    </CRow>
                  </div>
                  <div class="w-100 d-flex flex-grow-1 moment_custom_message">
                    <CRow class="h-100 w-100 m-0">
                      <CCol cols="12" lg="12" md="12" sm="12">
                        <div class="h-100 w-100 text-center position-relative">
                          <div class="h-100 w-100 d-flex align-items-center justify-content-center custom_message_container">
                            <span @click.stop="setCustomMessageFieldFocus" class="text-center">{{moment.custom_message ? moment.custom_message : $t('sm.Add_your_custom_message')}}</span>
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                  </div>
                  <div class="w-100 moment_details">
                    <CRow class="w-100 m-0">
                      <CCol cols="12" lg="12" md="12" sm="12">
                        <div class="d-flex align-items-center">
                          <div class="d-flex flex-grow-1 align-items-center">
                            <div class="h-100 d-flex align-items-center label_container">
                              <label class="m-0"><b>{{$t('common.From')}}:</b></label>
                            </div>
                            <div v-if="moment.sender" class="d-flex align-items-center moment_sender">
                              <div v-if="cdnBaseUrl"
                                    class="profile_image mr-1"
                                    v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-sender/' + moment.sender.loyalty_moment_sender_id_external + '/' + moment.sender.profile_image_id + '/' + clientToken + ')' }">                      
                              </div>
                              <div v-else-if="moment.sender.sender_alias" class="profile_icon mr-1">
                                <userProfileAvatar :username="moment.sender.sender_alias" :size="30"/>
                              </div>
                              <span>{{moment.sender.sender_alias}}</span>
                            </div>
                            <div v-else class="d-flex align-items-center moment_sender">
                              <div v-if="user.profile_image && user.original_company_id_external && user.original_company_id_external !== user.company_id_external"
                                  class="profile_image d-inline-block align-middle"           
                                  v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile-original/' + user.user_id_external + '/' + user.profile_image + '/' + clientToken + ')' }">
                              </div>
                              <div v-else-if="user.profile_image"
                                  class="profile_image d-inline-block align-middle"           
                                  v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + user.user_id_external + '/' + user.profile_image + '/' + clientToken + ')' }">
                              </div>
                              <div v-else-if="user.name" class="profile_icon d-inline-flex align-middle">
                                <userProfileAvatar :username="user.name" :size="20"/>
                              </div>
                              <div class="ml-1">
                                <span>{{user.name}}</span>
                              </div>
                            </div>
                          </div>                            
                          <div v-if="moment.grant_points && moment.moment_points > 0" class="d-flex flex-grow-1 align-items-center justify-content-end currency">
                            <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken" class="mr-1"/>
                            <span class="d-flex align-items-center">
                              <span class="count mr-1">{{moment.moment_points}}</span> {{moment.moment_points !== 1 ? $t('common.coins') : $t('common.coin')}}
                            </span>
                          </div>
                        </div>                          
                      </CCol>
                    </CRow>
                  </div>
                </div>
              </div>
            </div>
          </CCol>
          <CCol cols="8" xl="8" lg="7" md="6" sm="12" xs="12" class="pt-0">
            <div class="moment_details">
              <!-- Smart Filters -->
              <CRow>
                <CCol cols="2" xl="2" lg="3" md="4" sm="3" xs="3" class="pt-0">
                  <div class="d-flex align-items-center">
                    <div class="d-flex details_icon">
                      <i class="fa-solid fa-sliders"/>
                    </div>
                    <div>
                      <label class="m-0"><b>{{$t('core.Smart_filters')}}</b></label>
                    </div>
                  </div>
                </CCol>
                <CCol cols="10" xl="10" lg="9" md="8" sm="9" xs="9" class="pt-0">
                  <div>
                    <CRow v-for="(filter, index) in moment.filters" v-bind:key="index">
                      <CCol cols="12" lg="12" class="pt-0 pb-0">
                        <div class="mb-2_5 d-flex filters">
                          <div class="filter_type">
                            <multiselect class="data_table open_absolute"
                                         v-model="filter.type" 
                                         :options="filter.priority === 1 ? groupFilterTypesPrio1 : groupFilterTypesPrio2" 
                                         :multiple="false"                
                                         :placeholder="$t('common.Select_filter_type')" 
                                         :selectLabel="$t('common.Add_filter_type')" 
                                         :selectedLabel="$t('Selected')"
                                         :deselectLabel="$t('common.Remove_filter_type')"
                                         track-by="type_tag" 
                                         label="type_name"
                                         :custom-label="customFilterTypeLabel"
                                         :disabled="filter.type !== null && filter.type !== undefined"
                                         @input="setupTypeFilter(filter.type.type_tag, index);">
                              <span slot="noResult">{{ $t('common.no_types_found') }}</span>
                            </multiselect>
                          </div>

                          <div v-if="filter.type" class="filter_condition ml-2" v-bind:class="{'mr-2' : filter.condition && !conditionsWithoutValue.includes(filter.condition.condition_tag)}">
                            <multiselect class="data_table open_absolute"
                                         v-model="filter.condition" 
                                         :options="typeFilterConditions" 
                                         :multiple="false"                
                                         :placeholder="$t('common.Select_filter_condition')" 
                                         :selectLabel="$t('common.Add_filter_condition')" 
                                         :selectedLabel="$t('Added')"
                                         :deselectLabel="$t('common.Remove_filter_condition')"
                                         track-by="condition_tag" 
                                         label="condition_name"
                                         :custom-label="customFilterConditionLabel"
                                         :disabled="filter.condition !== null && filter.condition !== undefined"
                                         @input="filter.condition && conditionsWithoutValue.includes(filter.condition.condition_tag) ? getMomentRecipients() : null;">
                              <span slot="noResult">{{ $t('common.no_conditions_found') }}</span>
                              <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                            </multiselect>
                          </div>

                          <div v-if="filter.type && filter.condition && !conditionsWithoutValue.includes(filter.condition.condition_tag)" class="filter_value">
                            <div v-if="filter.type.type_tag == 'department'">
                              <multiselect class="data_table open_absolute"
                                           v-model="filter.value" 
                                           :options="groupFilterDepartments" 
                                           :multiple="false"                
                                           :placeholder="$t('common.Select_department')"
                                           :selectLabel="$t('common.Add_department')" 
                                           :selectedLabel="$t('Selected')"
                                           :deselectLabel="$t('common.Remove_department')"
                                           track-by="department_id" 
                                           label="department_name"
                                           @search-change="asyncFindDepartment"
                                           @input="getMomentRecipients();">
                                <span slot="noResult">{{ $t('common.no_departments_found') }}</span>
                                <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                              </multiselect>
                            </div>
                            <div v-if="filter.type.type_tag == 'team'">
                              <multiselect class="data_table open_absolute"
                                           v-model="filter.value" 
                                           :options="groupFilterTeams" 
                                           :multiple="false"                
                                           :placeholder="$t('common.Select_team')" 
                                           :selectLabel="$t('common.Add_team')" 
                                           :selectedLabel="$t('Selected')"
                                           :deselectLabel="$t('common.Remove_team')"
                                           track-by="team_id" 
                                           label="team_name"
                                           @search-change="asyncFindTeam"
                                           @input="getMomentRecipients();">
                                <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
                                <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                              </multiselect>
                            </div>
                            <div v-if="filter.type.type_tag == 'user'">
                              <multiselect class="data_table open_absolute"
                                           v-model="filter.value" 
                                           :options="groupFilterRecipients" 
                                           :multiple="false"                
                                           :placeholder="$t('common.Set_employee')" 
                                           :selectLabel="$t('common.Select_employee')" 
                                           :selectedLabel="$t('Selected')"
                                           :deselectLabel="$t('common.Deselect_employee')"
                                           track-by="user_id_external" 
                                           label="label"                        
                                           @search-change="asyncFindRecipient"
                                           @input="getMomentRecipients();">
                                <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                                <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                              </multiselect>  
                            </div>
                            <div v-if="filter.type.type_tag == 'department_manager'">
                              <multiselect class="data_table open_absolute"
                                           v-model="filter.value" 
                                           :options="groupFilterDepartmentManagers" 
                                           :multiple="false"                
                                           :placeholder="$t('common.Set_manager')" 
                                           :selectLabel="$t('common.Select_manager')" 
                                           :selectedLabel="$t('Selected')"
                                           :deselectLabel="$t('common.Deselect_manager')"
                                           track-by="user_id_external" 
                                           label="label"                        
                                           @search-change="asyncFindDepartmentManager"
                                           @input="getMomentRecipients();">
                                <span slot="noResult">{{ $t('common.No_managers_found') }}</span>
                                <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                              </multiselect>
                            </div>                               
                            <div v-if="filter.type.type_tag == 'team_manager'">
                              <multiselect class="data_table open_absolute"
                                           v-model="filter.value" 
                                           :options="groupFilterTeamManagers" 
                                           :multiple="false"                
                                           :placeholder="$t('common.Set_employee')" 
                                           :selectLabel="$t('common.Select_employee')" 
                                           :selectedLabel="$t('Selected')"
                                           :deselectLabel="$t('common.Deselect_employee')"
                                           track-by="user_id_external" 
                                           label="label"                        
                                           @search-change="asyncFindTeamManager"
                                           @input="getMomentRecipients();">
                                <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                                <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                              </multiselect>
                            </div>                            
                            <div v-if="filter.type.type_tag == 'target_group'">
                              <multiselect class="data_table open_absolute"
                                           v-model="filter.value" 
                                           :options="groupFilterTargetGroups" 
                                           :multiple="false"                
                                           :placeholder="$t('common.Select_group')" 
                                           :selectLabel="$t('common.Add_group')" 
                                           :selectedLabel="$t('Selected')"
                                           :deselectLabel="$t('common.Remove_group')"
                                           track-by="group_id" 
                                           label="title"                      
                                           @search-change="asyncFindTargetGroup"
                                           @input="getMomentRecipients();">
                                <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
                                <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                              </multiselect>  
                            </div>                  
                            <div v-if="['date_started', 'date_left'].includes(filter.type.type_tag)">
                              <multiselect class="data_table open_absolute"
                                           v-model="filter.value" 
                                           :options="typeFilterValues" 
                                           :multiple="false"                
                                           :placeholder="$t('common.Select_filter_value')" 
                                           :selectLabel="$t('common.Add_filter_value')" 
                                           :selectedLabel="$t('Added')"
                                           :deselectLabel="$t('common.Remove_filter_value')"
                                           track-by="type_value_tag" 
                                           label="type_value_name"
                                           :custom-label="customFilterTypeValueLabel"
                                           @input="getMomentRecipients();">
                                <span slot="noResult">{{ $t('common.no_values_found') }}</span>
                              </multiselect>   
                            </div>
                            <div v-if="userAttributes.includes(filter.type.type_tag)">
                              <CInput v-debounce:1s="getMomentRecipients"
                                      type="text"
                                      class="attribute mb-0"
                                      v-model="filter.value">
                              </CInput>
                            </div>
                          </div>

                          <CButton class="ml-2 mr-0 d-inline-block" color="primary" @click="removeFilterOption(index);">
                            <i class="fas fa-times"/>
                          </CButton>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol cols="12" lg="12" class="pt-0 pb-0">
                        <CButton color="primary" @click="addFilterOption(1);">
                          <i class="fas fa-plus mr-1"/>
                          <span>{{$t('common.Add_required_filter')}}</span>
                        </CButton>
                        <CButton color="primary" @click="addFilterOption(2);" :disabled="!requiredFilterSet">
                          <i class="fas fa-plus mr-1"/>
                          <span>{{$t('common.Add_additional_filter')}}</span>
                        </CButton>
                      </CCol>
                    </CRow>
                  </div>

                  <div v-if="moment.recipients.length > 0" class="d-flex align-items-center mt-2 moment_recipients">
                    <div v-for="(recipient, index) in moment.recipients.slice(0, 10)" :key="index" class="recipient">
                      <div v-if="recipient.profile_image" class="profile_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + recipient.user_id_external + '/' + recipient.profile_image + '/' + clientToken + ')' }"></div>
                      <div v-else class="profile_icon">
                        <userProfileAvatar :username="recipient.name" :size="30"/>
                      </div>
                    </div>                                        
                    <div v-if="moment.recipients.length > 10" class="ml-1">
                      <span><b>{{moment.recipients.length - 10}}+</b></span>
                    </div>
                    <div @click="setMomentRecipientsPagination(); momentRecipientsModal = true;" class="ml-1 pointer">
                      <span>{{$t('common.Details')}}</span>
                    </div>
                  </div>
                </CCol>
              </CRow>
              <!-- From -->
              <CRow>
                <CCol cols="2" xl="2" lg="3" md="4" sm="3" xs="3" class="pt-0">
                  <div class="d-flex align-items-center">
                    <div class="d-flex details_icon">
                      <i class="fa-solid fa-user-pen"/>
                    </div>
                    <div>
                      <label class="m-0"><b>{{$t('common.From')}}</b></label>
                    </div>
                  </div>
                </CCol>
                <CCol cols="10" xl="10" lg="9" md="8" sm="9" xs="9" class="pt-0">
                  <div class="d-flex align-items-center moment_sender">
                    <div v-if="user.profile_image && user.original_company_id_external && user.original_company_id_external !== user.company_id_external"
                          class="profile_image d-inline-block align-middle"           
                          v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile-original/' + user.user_id_external + '/' + user.profile_image + '/' + clientToken + ')' }">
                    </div>
                    <div v-else-if="user.profile_image"
                          class="profile_image d-inline-block align-middle"           
                          v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + user.user_id_external + '/' + user.profile_image + '/' + clientToken + ')' }">
                    </div>
                    <div v-else-if="user.name"
                        class="profile_icon d-inline-flex align-middle">
                      <userProfileAvatar :username="user.name" :size="20"/>
                    </div>
                    <div class="ml-1">
                      <span>{{user.name}}</span>
                    </div>
                  </div>
                </CCol>
              </CRow>
              <!-- Alias -->
              <CRow v-if="momentSenderAliases.length > 0">
                <CCol cols="2" xl="2" lg="3" md="4" sm="3" xs="3" class="pt-0">
                  <div class="d-flex align-items-center">
                    <div class="d-flex details_icon">
                      <i class="fa-solid fa-at"/>
                    </div>
                    <div>
                      <label class="m-0"><b>{{$t('common.Send_as_alias')}}</b></label>
                    </div>
                  </div>
                </CCol>
                <CCol cols="10" xl="10" lg="9" md="8" sm="9" xs="9" class="pt-0">
                  <multiselect class="data_table"
                                v-model="moment.sender" 
                                :options="momentSenderAliases"
                                :multiple="false"
                                :hide-selected="false"
                                :close-on-select="true"
                                :placeholder="$t('common.Select_alias')" 
                                :selectLabel="$t('common.Add_alias')" 
                                :selectedLabel="$t('Added')"
                                :deselectLabel="$t('common.Remove_alias')"
                                track-by="loyalty_moment_sender_id_external" 
                                label="sender_alias">
                    <span slot="noResult">{{ $t('common.No_aliases_found') }}</span>
                  </multiselect>
                </CCol>
              </CRow>
              <!-- Points -->
              <CRow>
                <CCol cols="2" xl="2" lg="3" md="4" sm="3" xs="3" class="pt-0">
                  <div class="d-flex align-items-center align-items-center">
                    <div class="d-flex details_icon">
                      <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                    </div>
                    <div>
                      <label class="m-0"><b>{{$t('common.Coins')}}</b></label>
                    </div>
                  </div>
                </CCol>
                <CCol cols="10" xl="10" lg="9" md="8" sm="9" xs="9" class="pt-0">
                  <div class="w-100">
                    <CRow class="w-100 moment_points">
                      <CCol class="pt-0 pb-0">
                        <CButton v-for="(value, name, key) in [0, 50, 100, 250]" :key="key" color="primary" @click="moment.moment_points = value; setMomentPointsGranted('button');" :class="{'selected' : moment.moment_points === value && !showCustomPointsSlider}">
                          <div class="d-flex align-items-center justify-content-center">
                            <div class="currency mr-1">
                              <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                            </div>
                            <div>
                              <span class="d-block">{{value}}</span>                                    
                            </div>
                          </div>
                        </CButton>
                        <CButton color="primary" @click="showCustomPointsSlider = true;" :class="{'selected' : showCustomPointsSlider}">
                          <div class="d-flex align-items-center justify-content-center">
                            <span>Custom</span>                                    
                          </div>
                        </CButton>                        
                      </CCol>
                    </CRow>
                    <CRow v-if="showCustomPointsSlider" class="w-100 m-0 mt-2 mb-2">
                      <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0 pb-0">                                
                        <b-slider size="is-medium" v-model="moment.moment_points" @input="setMomentPointsGranted('slider')" :min="0" :max="500" :step="10" rounded>
                          <div class="b-slider-tick null">
                            <span class="b-slider-tick-label">0</span>
                          </div>
                          <template v-for="val in 500">                                      
                            <b-slider-tick v-if="(val % 50 === 0)" :value="val" :key="val">{{ val ? val : 0 }}</b-slider-tick>                                   
                          </template>
                        </b-slider>
                      </CCol>        
                    </CRow>

                  </div>
                </CCol>
              </CRow>
              <!-- Custom Message -->
              <CRow>
                <CCol cols="2" xl="2" lg="3" md="4" sm="3" xs="3" class="pt-0">
                  <div class="d-flex align-items-center">
                    <div class="d-flex details_icon">
                      <i class="fa-solid fa-message"/>
                    </div>
                    <div>
                      <label class="m-0"><b>{{$t('common.Message')}}</b><span class="d-block">{{$t('common.optional')}}</span></label>
                    </div>
                  </div>
                </CCol>
                <CCol cols="10" xl="10" lg="9" md="8" sm="9" xs="9" class="pt-0">
                  <b-input ref="customMessageInput" @input="countdown()" @focus="showMomentCardBack" v-model="moment.custom_message" type="textarea" rows="5"></b-input>
                  <p v-if="remainingCount.custom_message > 0" class="countdown mt-2 mb-0">{{remainingCount.custom_message}} {{ $t('common.characters_remaining') }}</p>
                  <p v-if="remainingCount.custom_message < 0" class="countdown warning mt-2 mb-0">{{moment.custom_message.length - maxCount.custom_message}} {{ $t('common.characters_too_much') }}</p>
                </CCol>
              </CRow>
            </div>
          </CCol>          
        </CRow>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="text-right">
            <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
            <CButton color="primary" @click="editSmartMoment();">
              <span><i class="fa-solid fa-check mr-1"/>{{$t('sm.Update_smart_spark')}}</span>
            </CButton>            
          </CCol>
        </CRow>
      </div>
    </div>

    <b-modal :can-cancel="['x']" :active.sync="momentRecipientsModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader>
          <span>{{$t('loyalty.Moment_recipients')}} ({{moment.recipients.length}})</span>
        </CCardHeader>
        <CCardBody class="pt-0 pb-0 ">
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="p-0" v-bind:class="{ 'pb-0' : listViewEnabled === false || isPaginated === false }">                      
              <div class="d-flex align-items-center" v-bind:class="{ 'mb-2_5' : listViewEnabled === true }">
                <b-switch v-if="moment.recipients.length > 0" class="mb-0" v-model="listViewEnabled" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
              </div>
              <div v-if="listViewEnabled">
                <CRow>
                  <CCol cols="12" sm="12" md="12" lg="12" class="p-0">
                    <b-table class="data_table" 
                              :data="moment.recipients"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="isPaginated"
                              :per-page="perPage"
                              :current-page.sync="currentPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition">
                      
                      <template slot-scope="props">
                        <b-table-column field="name" :label="$t('common.Name')">
                          <div @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external });" class="pointer">
                            <div v-if="props.row.profile_image" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>
                            <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                              <userProfileAvatar :username="props.row.name" :size="25"/>
                            </div>
                            <b>{{props.row.name}}</b>
                          </div>
                        </b-table-column>
                        <b-table-column field="department_name" :label="$t('common.Department')">
                          <span>{{props.row.department_name}}</span>
                        </b-table-column>                        
                        <b-table-column field="team_name" :label="$t('common.Team')">
                          <span>{{props.row.team_name}}</span>
                        </b-table-column>                        
                      </template>
                    </b-table>
                  </CCol>
                </CRow>
              </div>
              <div v-else>
                <CRow class="tile-container">
                  <CCol cols="3" xl="3" lg="3" md="3" sm="6" v-for="recipient in currentMomentRecipients[currentMomentRecipientsPage - 1]" v-bind:key="recipient.user_id_external" class="p-0">
                    <userProfileCard :userData="recipient" class="member"/>
                  </CCol>
                </CRow>
                <hr v-if="moment.recipients.length > momentRecipientsPerPage" class="m-0">
                <CRow v-if="moment.recipients.length > momentRecipientsPerPage">
                  <CCol cols="12" md="12">
                    <v-pagination class="justify-content-end" v-model="currentMomentRecipientsPage" :length="momentRecipientsPages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                  </CCol>
                </CRow>
              </div>
            </CCol>
          </CRow>  
        </CCardBody>
        <CCardFooter>          
          <CButton color="secondary" @click="momentRecipientsModal = false"><i class="fas fa-times mr-1"/>{{$t('close')}}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';
import loadingSpinner from '@/components/common/loadingSpinner.vue';

import userProfileCard from "@/components/common/userProfileCard.vue";
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'editSmartMoment',
  props: ['history', 'modules', 'platformPermissions', 'smartMomentIdExternal'],
  components: {
    Multiselect,
    loadingSpinner,
    userProfileCard,
    userProfileAvatar    
  },
  watch: { 
    $momentIdExternal: {
      handler() {
        this.getMomentDetails();
        this.getMomentSenderAliases();
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      // Common
      apiBaseUrl: null,
      cdnBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      companyIdExternal: null,
      featureUnavailableModal: false,
      productLicenses: {
        loyalty_licence_tag: null
      },      
     // Moment    
      moment: { recipients: [] },
      momentSenderAliases: [],
      showCustomPointsSlider: false,     
      currentMomentRecipientsPage: 1,
      currentMomentRecipients: {},
      momentRecipientsPerPage: 12 ,
      momentRecipientsPages: 0,      
      sendButtonDisabled: false,
      sendingMoment: false,
      isFlipped: false,
      isContentLoaded: false,
      updateTimer: null,
      heightUpdateAttempts: 0,
      maxHeightUpdateAttempts: 5,
      minHeight: 550,      
      user: [],
      // Moment Recipients
      recipients: [],
      momentRecipientsType: 'smart_filters',    
      momentRecipientsModal: false,
      // Moment Recipients - Smart Filters
      requiredFilterSet: false,
      groupFilterTypesPrio1: [],
      groupFilterTypesPrio2: [],
      groupFilterConditions: [],
      groupFilterValues: [],
      groupFilterTargetGroups: [], 
      groupFilterRecipients: [],
      groupFilterDepartmentManagers: [],
      groupFilterTeamManagers: [],      
      groupFilterDepartments: [],
      groupFilterTeams: [],
      typeFilterConditions: [],      
      typeFilterValues: [],
      currentPageEmployees: {},
      employeesCurrentPage: 1,
      employeesPerPage: 12,
      employeePages: 0,       
      userAttributes: ['hours_on_contract', 'meyer_briggs', 'disc', 'office_based', 'division', 'language', 'persona_1', 'persona_2', 'persona_3', 'work_location', 'user_function', 'years_of_service', 'days_of_service'],
      conditionsWithoutValue: ['is_not_set', 'is_today'],      
      // Tables
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,      
      listViewEnabled: false,
      // Others
      maxCount: {
        custom_message: 1000
      },
      remainingCount: {
        custom_message: 1000
      }
    }
  },
  methods: {
    // Moment
    setMomentPointsGranted(inputSource) {
      // Update the grant_points value
      this.moment.grant_points = (this.moment.moment_points > 0) ? true : false;
      // Hide the points slider if a button is used as input
      if(inputSource === 'button') this.showCustomPointsSlider = false;
      // Force update
      this.$forceUpdate();
    },
    getMomentDetails() {
      // Get the Moment details      
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/smart-moment/' + this.smartMomentIdExternal)
      .then(res => {
        this.moment = res.data.data;
        // Set the moment points value
        this.moment.moment_points = this.moment.points;
        // Set the grant points value
        (this.moment.moment_points > 0) ? this.moment.grant_points = true : this.moment.grant_points = false;
        // Check if the points slider should be shown
        if(![0, 50, 100, 250].includes(this.moment.moment_points)) this.showCustomPointsSlider = true;
        // Set the requiredFilterSet value
        (this.moment.filters.some(filter => filter.priority === 1)) ? this.requiredFilterSet = true : this.requiredFilterSet = false;
        // Set the momentRecipientsType value
        this.momentRecipientsType = this.moment.recipients_type;
        // Trigger the countdown function
        this.countdown();
        // Setup observers and event listeners
        setTimeout(function() {
          this.setupContentObserver();
          this.resetHeightUpdate();
          // Add resize listener
          window.addEventListener('resize', this.updateCardHeight);
        }.bind(this), 100);        
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    validateMoment(params) {      
      let filters = params.momentData.filters;
      let grant_points = params.momentData.grant_points;

      // Check if base information for the smart moment
      if(filters.length === 0 || grant_points === null) {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        return false;
      } 
      
      // Validate that at least one filter has priority value 1
      let hasValidFilter = false;
      if(filters && Array.isArray(filters)) {
        hasValidFilter = filters.some(filter => filter.priority === 1);
      }      
      
      if(!hasValidFilter) {
        this.$buefy.toast.open({ message: this.$t('common.Invalid_filters'), type: 'is-danger', duration: 2000 });
        return false;
      }    
      
      return true;
    },
    async editSmartMoment() {
      let _this = this;
      // Set the params
      let params = {};
      params.momentData = this.moment;
      params.momentData.recipients_type = this.momentRecipientsType;
      if(!this.moment.json) params.momentData.json = null;
      if(!this.moment.html) params.momentData.html = null;
      
      if(this.moment.moment_points) params.momentData.points = parseInt(this.moment.moment_points);
      
      (params.momentData.send_moment_email == true) ? params.momentData.send_email = 1 : params.momentData.send_email = 0;
      (params.momentData.send_moment_push == true) ? params.momentData.send_push = 1 : params.momentData.send_push = 0;
      
      if(params.momentData.grant_points == true) {
        params.momentData.points_granted = 1;
      } else {
        params.momentData.points_granted = 0;
        params.momentData.points = null;
      }

      if(params.momentData.filters) {
        params.momentData.recipient_filters = params.momentData.filters.filter(function (el) {
          return el.type !== undefined && 
                el.condition !== undefined && (
                  (_this.conditionsWithoutValue.includes(el.condition.condition_tag) === false && el.value !== undefined) || 
                  (_this.conditionsWithoutValue.includes(el.condition.condition_tag) === true)
                );
        });
      }

      // Validate the Moment
      const momentValid = await this.validateMoment(params);

      if(momentValid) {
        await axios.put(process.env.VUE_APP_API_URL + '/v1/loyalty/smart-moment/' + this.smartMomentIdExternal, params)
        .then(res => {  
          this.$buefy.toast.open({ message: this.$t('sm.Smart_spark_updated'), type: 'is-success', duration: 2000 });
          // Close the sidebar
          this.closeSidebarRight();
          // Emit the update_loyalty_smart_moment_details event
          this.$bus.$emit('update_loyalty_smart_moment_details', this.smartMomentIdExternal);  
        })
        .catch(err => {
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });  
      }
    },
    getMomentSenderAliases() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-sender/aliases/my')
      .then(res => {
        this.momentSenderAliases = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    // Moment Recipients
    setMomentRecipientsPagination() {
      // Set the pagination to the first page
      this.currentMomentRecipientsPage = 1;
      // Reset the momentRecipientsPages value
      this.momentRecipientsPages = 0;
      // Loop througb the headcountDetails to divide the data in pages         
      for (let i = 0; i < this.moment.recipients.length; i = i + this.momentRecipientsPerPage) {
        this.currentMomentRecipients[this.momentRecipientsPages] = this.moment.recipients.slice(i, i + this.momentRecipientsPerPage);
        this.momentRecipientsPages++;
      }
    }, 
    // Moment Recipients - Smart Filters
    getMomentRecipients() {
      let _this = this;
      let params = {};
      params.use_priority = true;

      params.filters = this.moment.filters.filter(function (el) {
        return el.type !== undefined && 
               el.condition !== undefined && (
                (_this.conditionsWithoutValue.includes(el.condition.condition_tag) === false && el.value !== undefined) || 
                (_this.conditionsWithoutValue.includes(el.condition.condition_tag) === true)
               );
      });
      
      if(params.filters.length > 0) {
        // Get the recipients if there are filters available
        axios.post(process.env.VUE_APP_API_URL + '/v1/core/targetgroup/members', params)
        .then(res => {
          this.moment.recipients = res.data.data;
          // Enable the pagination if necessary
          (this.moment.recipients.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;         
          // Set the pagination to the first page
          this.employeesCurrentPage = 1;
          // Reset the employeePages value
          this.employeePages = 0;
          // Loop through the Moment to set the pagination            
          for (let i = 0; i < this.moment.recipients.length; i = i + this.employeesPerPage) {
            this.currentPageEmployees[this.employeePages] = this.moment.recipients.slice(i, i + this.employeesPerPage);
            this.employeePages++;
          }
          // Check if the pagination for the table should be enables
          this.moment.recipients.length > this.perPage ? this.isPaginated = true : this.isPaginated = false;        
          // Force update
          this.$forceUpdate();
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Reset the recipients array if there are no filters available
        this.moment.recipients = [];
      }
    },
    addFilterOption(priority) {
      // Add new filter to filters array
      this.moment.filters.push({priority: priority});
      // Set the requiredFilterSet value
      (this.moment.filters.some(filter => filter.priority === 1)) ? this.requiredFilterSet = true : this.requiredFilterSet = false;      
      // Force update
      this.$forceUpdate();
    },
    removeFilterOption(index) {
      // Remove filter from filters array
      this.moment.filters.splice(index, 1);
      // Set the requiredFilterSet value
      (this.moment.filters.some(filter => filter.priority === 1)) ? this.requiredFilterSet = true : this.requiredFilterSet = false;      
      // Update the recipients
      this.getMomentRecipients();
      // Force update
      this.$forceUpdate();
    },
    getGroupFilterTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types')
      .then(res => {      
        let groupFilterTypes = res.data.data;

        this.groupFilterTypesPrio1 = groupFilterTypes.filter(item => item.priority == 1);
        this.groupFilterTypesPrio2 = groupFilterTypes.filter(item => item.priority == 2);
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTypeValues() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types/values')
      .then(res => {
        this.groupFilterValues = res.data.data;
        // Set the typeFilterValues array
        this.typeFilterValues = this.groupFilterValues;        
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getGroupFilterConditions () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/conditions')
      .then(res => {
        this.groupFilterConditions = res.data.data;
        // Set the typeFilterConditions array
        this.typeFilterConditions = this.groupFilterConditions;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTypeFilterConditions(filterTypeTag, filterIndex) {
      // Reset the typeFilterConditions array
      this.typeFilterConditions = this.groupFilterConditions;
      // Filter the typeFilterConditions based on the filter type tag
      this.typeFilterConditions = this.typeFilterConditions.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
      // Auto set the condition if the filter type has only one available
      if(this.typeFilterConditions.length === 1) {
        this.moment.filters[filterIndex].condition = this.typeFilterConditions[0];      

        if(this.conditionsWithoutValue.includes(this.moment.filters[filterIndex].condition.condition_tag)) {
          this.getMomentRecipients();
        }
      }
    },    
    getTypeFilterValues(filterTypeTag) {
      // Reset the typeFilterValues array
      this.typeFilterValues = this.groupFilterValues;
      // Filter the typeFilterValues based on the filter type tag
      this.typeFilterValues = this.typeFilterValues.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
    },
    setupTypeFilter(filterTypeTag, filterIndex) {
      // Get the filter type conditions
      this.getTypeFilterConditions(filterTypeTag, filterIndex);
      // Get the filter type values
      this.getTypeFilterValues(filterTypeTag);
      // Get the filter values
      if(filterTypeTag === 'department') {
        this.getGroupFilterDepartments();        
      } else if(filterTypeTag === 'team') {
        this.getGroupFilterTeams();        
      } else if(filterTypeTag === 'user') {
        this.getGroupFilterRecipients();        
      } else if(filterTypeTag === 'department_manager') {
        this.getGroupFilterDepartmentManagers();        
      } else if(filterTypeTag === 'team_manager') {
        this.getGroupFilterTeamManagers();        
      } else if(filterTypeTag === 'target_group') {
        this.getGroupFilterTargetGroups();        
      }      
      // Force update
      this.$forceUpdate();
    },
    customFilterTypeLabel({type_tag}) {
      return this.$t('filter.' + type_tag);
    },    
    customFilterConditionLabel({condition_tag}) {
      return this.$t('condition.' + condition_tag);
    },    
    customFilterTypeValueLabel({type_value_tag}) {
      return this.$t('filter_value.' + type_value_tag);
    },
    getGroupFilterTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.groupFilterTargetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterRecipients() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/employees/multiselect')
      .then(res => {
        this.groupFilterRecipients = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterDepartmentManagers() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/department-managers/multiselect')
      .then(res => {
        this.groupFilterDepartmentManagers = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTeamManagers() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/team-managers/multiselect')
      .then(res => {
        this.groupFilterTeamManagers = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getGroupFilterDepartments() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departments/multiselect')
      .then(res => {
        this.groupFilterDepartments = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTeams() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/teams/multiselect')
      .then(res => {
        this.groupFilterTeams = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    // Async Search
    asyncFindUser(query) {
      let searchTerm = query;
      this.recipients = [];

      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/recipients/' + searchTerm)
        .then(res => {      
          this.recipients = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }    
    },    
    asyncFindTargetGroup(query) {
      let searchTerm = query;
      this.groupFilterTargetGroups = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/targetgroup/' + searchTerm)
        .then(res => {      
          this.groupFilterTargetGroups = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },    
    asyncFindRecipient(query) {
      let searchTerm = query;
      this.groupFilterRecipients = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/recipients/' + searchTerm)
        .then(res => {      
          this.groupFilterRecipients = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindDepartmentManager(query) {
      let searchTerm = query;
      this.groupFilterDepartmentManagers = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/department-managers/' + searchTerm)
        .then(res => {      
          this.groupFilterDepartmentManagers = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeamManager(query) {
      let searchTerm = query;
      this.groupFilterTeamManagers = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/team-managers/' + searchTerm)
        .then(res => {      
          this.groupFilterTeamManagers = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },    
    asyncFindDepartment(query) {
      let searchTerm = query;
      this.groupFilterDepartments = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/departments/' + searchTerm)
        .then(res => {      
          this.groupFilterDepartments = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeam(query) {
      let searchTerm = query;
      this.groupFilterTeams = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/teams/' + searchTerm)
        .then(res => {      
          this.groupFilterTeams = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    // Other
    getUserDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/me')
      .then(res => {
        this.user = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },   
    countdown () {
      if(this.moment.custom_message) this.remainingCount.custom_message = this.maxCount.custom_message - this.moment.custom_message.length;
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    toggleFlip() {
      this.isFlipped = !this.isFlipped;
    },    
    showMomentCardBack() {
      if(!this.isFlipped) this.isFlipped = true;
    },
    setCustomMessageFieldFocus() {
      this.$refs.customMessageInput.focus();
    },
    updateCardHeight() {
      if(this.updateTimer) clearTimeout(this.updateTimer);

      const updateHeight = () => {
        const momentCard = this.$refs.momentCard;
        const momentContainer = this.$refs.momentContainer;
        
        if (!momentCard || !momentContainer) {
          if (this.heightUpdateAttempts < this.maxHeightUpdateAttempts) {
            this.heightUpdateAttempts++;
            this.updateTimer = setTimeout(updateHeight, 100);
          }
          return;
        }

        // Get all images within the moment card
        const images = momentCard.getElementsByTagName('img');
        const imagePromises = Array.from(images).map(img => {
          return new Promise((resolve) => {
            if (img.complete) {
              resolve();
            } else {
              img.onload = () => resolve();
              img.onerror = () => resolve(); // Handle failed image loads
            }
          });
        });

        // Wait for all images to load before calculating height
        Promise.all(imagePromises).then(() => {
          const height = Math.max(momentCard.offsetHeight, this.minHeight);

          if (height > 0) {
            momentContainer.style.height = `${height}px`;
            this.isContentLoaded = true;
          } else if (this.heightUpdateAttempts < this.maxHeightUpdateAttempts) {
            this.heightUpdateAttempts++;
            this.updateTimer = setTimeout(updateHeight, 100);
          }
        });
      };

      this.$nextTick(updateHeight);
    },
    resetHeightUpdate() {
      this.heightUpdateAttempts = 0;
      this.isContentLoaded = false;
      this.updateCardHeight();
    },
    setupContentObserver() {
      // Cleanup existing observer if any
      if(this.contentObserver) this.contentObserver.disconnect();
      // Create new observer
      this.contentObserver = new MutationObserver((mutations) => {
        let shouldUpdate = false;
        mutations.forEach(mutation => {
          if(mutation.type === 'childList' || mutation.type === 'characterData' || mutation.type === 'attributes') shouldUpdate = true;
        });

        if(shouldUpdate) this.updateCardHeight();
      });

      // Start observing if momentCard exists
      if (this.$refs.momentCard) {
        this.contentObserver.observe(this.$refs.momentCard, {
          childList: true,
          subtree: true,
          characterData: true,
          attributes: true
        });
      }
    }     
  },
  async mounted() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    
    this.productLicenses = await this.fetchProduuctLicenseData();

    this.getGroupFilterTypes();
    this.getGroupFilterConditions();
    this.getGroupFilterTypeValues();    
    this.getUserDetails();  
  },
  beforeDestroy() {        
    // Cleanup
    window.removeEventListener('resize', this.updateCardHeight);
    
    if(this.updateTimer) clearTimeout(this.updateTimer);
    if(this.contentObserver) this.contentObserver.disconnect();  
  }  
}
</script>