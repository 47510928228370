<template>
  <CHeader fixed light :class="{'hide_background' : showHeaderBackground === false}">
    <CToggler in-header class="ml-3 d-lg-none" v-c-emit-root-event:toggle-sidebar-mobile/>
    <CToggler in-header class="ml-3 d-md-down-none" v-c-emit-root-event:toggle-sidebar @click="toggleSidebar()"/>
    
    <CHeaderBrand :src="getLogoSrc()" :class="{'show_header_logo' : showHeaderLogo}"/>
    <CHeaderNav class="mr-4 ml-auto header_nav_right">
      <TheHeaderSearchEmployee/>
      <TheHeaderActions/>
      <TheHeaderAccount/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderActions from './TheHeaderActions'
import TheHeaderAccount from './TheHeaderAccount'
import TheHeaderSearchEmployee from './TheHeaderSearchEmployee'

import axios from 'axios'

export default {
  name: 'TheHeader',
  props: ['showHeaderLogo', 'showHeaderBackground'],
  components: {
    TheHeaderActions,
    TheHeaderAccount,
    TheHeaderSearchEmployee
  },
  data() {
    return {
      modules: {
        tasks: 0
      },
      apiBaseUrl: null,
      cdnBaseUrl: null,
      clientToken: null,
      companyIdExternal: null,
      companyImageId: null,
      environmentTag: null,
      environmentHelpers: {
        environment_help_url: null
      }       
    }
  },
  methods: {
    getLogoSrc() {
      if(!this.companyImageId) {
        return `${this.apiBaseUrl}/v1/common/cdn/file/image/base-dashboard/logo/${this.environmentTag}_logo.png/${this.clientToken}`;
      } else {
        return `${this.apiBaseUrl}/v1/common/cdn/file/image/base-dashboard/${this.companyIdExternal}/${this.companyImageId}.png/${this.clientToken}`;
      }
    },
    toggleSidebar() {
      this.$bus.$emit('toggle_sidebar_menu');
    },
    openHowTo() {      
      var win = window.open(this.environmentHelpers.environment_help_url, '_blank');
      win.focus();
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;      
      })
      .catch(err => {
        console.error(err); 
      });
    }    
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');
    if(localStorage.getItem('companyImageId') !== null) this.companyImageId = localStorage.getItem('companyImageId');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    if(localStorage.getItem('environmentHelpers') !== null) this.environmentHelpers = JSON.parse(localStorage.getItem('environmentHelpers'));
    // Check the modules
    this.checkModules();
    
    this.$bus.$on('theming_updated', (e) => {
      if(localStorage.getItem('companyImageId') !== null) this.companyImageId = localStorage.getItem('companyImageId');
    });
  },
  beforeDestroy() {
    this.$bus.$off('theming_updated');
  }  
}
</script>
