<template>
  <loadingSpinner mode="auto" v-if="!modulesLoaded || !platformPermissionsLoaded || !platformRolesLoaded" :content="$t('menu.Loading_menu')"/>

  <CSidebarNav v-else>
    
    <!-- Home -->
    <CSidebarNavItem name="Home" navItem="home" to="/dashboard" fontIcon="fa-solid fa-house" @click.native="toggleDropdown('home')"></CSidebarNavItem>

    <div v-if="showAllMenuItems">
      <!-- Measure -->
      <CSidebarNavDropdown v-if="modules.satisquestions === 1 && checkProductPermissions('measure')" name="Measure" navItem="measure" fontIcon="fas fa-thermometer-half" :show.sync="dropdownOpenStatuses.measure">
        <CSidebarNavItem v-if="checkPermission('measure.sq.overview')" name="SatisQuestions®" navItem="satisquestions" to="/measure/satisquestions" fontIcon="fas fa-tachometer-alt" @click.native="toggleDropdown('measure')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('measure.bm.overview') && modules.burning_matters === 1" name="Burning Matters" navItem="burning-matters" to="/measure/burning-matters" fontIcon="fa-solid fa-fire" @click.native="toggleDropdown('measure')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('measure.configure') && modules.auto_moments === 1" name="Auto-Moments" navItem="auto-moments" to="/measure/auto-moments" fontIcon="fa-solid fa-calendar-day" @click.native="toggleDropdown('measure')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('measure.configure')" name="Configure" navItem="configure" to="/measure/configure" fontIcon="fas fa-cog" @click.native="toggleDropdown('measure')"></CSidebarNavItem>
      </CSidebarNavDropdown>

      <!-- Loyalty -->
      <CSidebarNavDropdown v-if="modules.employee_loyalty_programme === 1 && checkProductPermissions('loyalty')" name="Spark Moments" navItem="loyalty" fontIcon="fa-solid fa-bolt" :show.sync="dropdownOpenStatuses.loyalty">
        <CSidebarNavItem v-if="modules.loyalty_moments === 1 && checkPermission('loyalty.moments.overview')" name="Sparks" navItem="moments" to="/loyalty/moments" fontIcon="fa-solid fa-envelope-open-text" @click.native="toggleDropdown('loyalty')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('loyalty.smartmoments.overview') && ['business', 'enterprise'].includes(productLicenses.loyalty_license_tag) && modules.loyalty_smart_moments === 1" name="Smart Sparks" navItem="auto-moments" to="/loyalty/smart-moments" fontIcon="fa-solid fa-calendar-day" @click.native="toggleDropdown('loyalty')"></CSidebarNavItem>
        <CSidebarNavItem v-if="modules.loyalty_benefits_moments === 1 && checkPermission('loyalty.benefits.moments.overview')" name="Benefits Moments " navItem="/loyalty/benefits/moments" to="/loyalty/benefits/moments" fontIcon="fa-solid fa-calendar-day" @click.native="toggleDropdown('loyalty')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('loyalty.store.overview')" name="Spark Shop" navItem="store" to="/loyalty/store" fontIcon="fas fa-store" @click.native="toggleDropdown('loyalty')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('loyalty.orders.overview')" name="Orders" navItem="orders" to="/loyalty/orders" fontIcon="fas fa-receipt" @click.native="toggleDropdown('loyalty')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('loyalty.momentsenders.overview') || (checkPermission('loyalty.budgetcaps') && ['business', 'enterprise'].includes(productLicenses.loyalty_license_tag))" name="Configure" navItem="configure" to="/loyalty/configure" fontIcon="fas fa-cog" @click.native="toggleDropdown('loyalty')"></CSidebarNavItem>
        <!-- <CSidebarNavItem name="Team Challenges" navItem="team-challenges" to="/loyalty/team-challenges" fontIcon="fas fa-award" @click.native="toggleDropdown('loyalty')"></CSidebarNavItem> -->
        <CSidebarNavItem v-if="company.payment_setup_completed === true && 
                               company.payment_type_tag === 'prepaid' && 
                               checkPermission('core.payments.prepaid.topup')" 
                         name="Top Up Wallet Balance"
                         navItem="prepaid-topup"
                         to="/core/payments/prepaid/topup"
                         fontIcon="fas fa-square-up-right"
                         @click.native="toggleDropdown('core')">        
        </CSidebarNavItem>        
      </CSidebarNavDropdown>

      <!-- Connect -->
      <CSidebarNavDropdown v-if="modules.social_wall === 1 && checkProductPermissions('connect')" name="Connect" navItem="connect" fontIcon="fas fa-volume-up" :show.sync="dropdownOpenStatuses.connect">
        <CSidebarNavItem v-if="checkPermission('connect.sw.overview')" name="Social Connect" navItem="schedule" to="/connect/schedule" fontIcon="fas fa-calendar-week" @click.native="toggleDropdown('connect')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('connect.flows.overview')" name="Flows" navItem="flows" to="/connect/flows" fontIcon="fas fa-stream" @click.native="toggleDropdown('connect')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('connect.deliver.overviewcampaigns') || checkPermission('connect.deliver.overviewtemplates') || modules.push_notifications === 1" 
                        name="Deliver"
                        navItem="deliver"
                        to="/connect/deliver"
                        fontIcon="fas fa-envelope"
                        @click.native="toggleDropdown('connect')">
        </CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('connect.senders.overview')" name="Senders" navItem="senders" to="/connect/senders" fontIcon="fas fa-users" @click.native="toggleDropdown('connect')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('connect.topics.overview')" name="Topics" navItem="topics" to="/connect/topics" fontIcon="fas fa-list-ul" @click.native="toggleDropdown('connect')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('connect.moments.overview') && modules.auto_moments === 1" name="Auto-Moments" navItem="auto-moments" to="/connect/auto-moments" fontIcon="fa-solid fa-calendar-day" @click.native="toggleDropdown('connect')"></CSidebarNavItem>
        <CSidebarNavItem v-if="(checkPermission('connect.signage.overview') || checkPermission('connect.signage.tickeroverview')) && modules.signage === 1" 
                        name="Signage"
                        navItem="signage"
                        to="/connect/signage"
                        fontIcon="fab fa-chromecast"
                        @click.native="toggleDropdown('connect')">
        </CSidebarNavItem>
        <CSidebarNavItem v-if="modules.connect_chats === 1 && checkPermission('connect.chats')" name="Chats" navItem="chats" to="/connect/chats" fontIcon="fas fa-comments" @click.native="toggleDropdown('connect')"></CSidebarNavItem>      
      </CSidebarNavDropdown>

      <!-- Knowledge -->
      <CSidebarNavDropdown v-if="modules.social_kb === 1 && checkProductPermissions('knowledge')" name="Knowledge" navItem="knowledge" fontIcon="fa-solid fa-lines-leaning" :show.sync="dropdownOpenStatuses.knowledge">
        <CSidebarNavItem v-if="checkPermission('knowledge.library.overview')" name="Library" navItem="library" to="/knowledge/library" fontIcon="fas fa-book" @click.native="toggleDropdown('knowledge')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('knowledge.submissions.overview')" name="Submissions" navItem="submissions" to="/knowledge/submissions" fontIcon="fas fa-inbox" @click.native="toggleDropdown('knowledge')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('knowledge.feedback')" name="Feedback" navItem="feedback" to="/knowledge/feedback" fontIcon="fas fa-headset" @click.native="toggleDropdown('knowledge')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('knowledge.configure') && modules.auto_moments === 1" name="Auto-Moments" navItem="auto-moments" to="/knowledge/auto-moments" fontIcon="fa-solid fa-calendar-day" @click.native="toggleDropdown('knowledge')"></CSidebarNavItem>
      </CSidebarNavDropdown>

      <!-- Learn -->
      <CSidebarNavDropdown v-if="modules.academy === 1 && checkProductPermissions('learn')" name="Learn" navItem="learn" fontIcon="fa-solid fa-user-graduate" :show.sync="dropdownOpenStatuses.learn">
        <CSidebarNavItem v-if="checkPermission('learn.catalog.overview')" name="Catalog" navItem="catalog" to="/learn/catalog" fontIcon="fas fa-graduation-cap" @click.native="toggleDropdown('learn')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('learn.onboarding.overview')" name="Onboarding" navItem="onboarding" to="/learn/onboarding" fontIcon="fas fa-hands-helping" @click.native="toggleDropdown('learn')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('learn.configure') && modules.auto_moments === 1" name="Auto-Moments" navItem="auto-moments" to="/learn/auto-moments" fontIcon="fa-solid fa-calendar-day" @click.native="toggleDropdown('learn')"></CSidebarNavItem>
      </CSidebarNavDropdown>

      <!-- Hub -->
      <CSidebarNavDropdown v-if="modules.resources_in_app === 1 && checkProductPermissions('hub')" name="Hub" navItem="hub" fontIcon="fas fa-th-large" :show.sync="dropdownOpenStatuses.hub">
        <CSidebarNavItem v-if="checkPermission('hub.resources.overview')" name="Resources" navItem="resources" to="/hub/resources" fontIcon="fas fa-user-gear" @click.native="toggleDropdown('hub')"></CSidebarNavItem>
        <!-- <CSidebarNavItem v-if="modules.tasks === 1" name="Tasks" navItem="tasks" to="/hub/tasks" fontIcon="fas fa-tasks" @click.native="toggleDropdown('hub')"></CSidebarNavItem> -->
        
        <!-- <CSidebarNavDropdown v-if="modules.todo_lists === 1" name="Todo Lists" navItem="todo-lists" fontIcon="fas fa-clipboard-list" :show.sync="dropdownOpenStatuses.todo">
          <CSidebarNavItem name="Lists" navItem="lists" to="/hub/todo-lists" fontIcon="fas fa-clipboard-list" @click.native="toggleDropdown('hub')"></CSidebarNavItem>
          <CSidebarNavItem name="Templates" navItem="templates" to="/hub/todo-lists/templates" fontIcon="fas fa-clipboard" @click.native="toggleDropdown('hub')"></CSidebarNavItem>
          <CSidebarNavItem name="Categories" navItem="categories" to="/hub/todo-lists/categories" fontIcon="fas fa-list-ul" @click.native="toggleDropdown('hub')"></CSidebarNavItem>
        </CSidebarNavDropdown> -->
      </CSidebarNavDropdown>

      <!-- Service -->
      <CSidebarNavDropdown v-if="modules.service === 1 && checkProductPermissions('service')" name="Service" navItem="service" fontIcon="fa-solid fa-headset" :show.sync="dropdownOpenStatuses.service">
        <CSidebarNavItem v-if="checkPermission('service.center')"
                         name="Questions"
                         navItem="questions"
                         to="/service/questions"
                         fontIcon="fas fa-question-circle"
                         @click.native="toggleDropdown('service')"
                         v-bind:class="{updates_available : serviceUpdatesAvailable}" 
                         :badge="{text : ''}">
        </CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('service.folders.overview')" name="Folders" navItem="folders" to="/service/questions/folders" fontIcon="fa-solid fa-folder" @click.native="toggleDropdown('service')"></CSidebarNavItem>
        <CSidebarNavItem v-if="modules.service_forms === 1 && checkPermission('service.forms.overview')" name="Forms" navItem="forms" to="/service/forms" fontIcon="fa-solid fa-file-lines" @click.native="toggleDropdown('service')"></CSidebarNavItem>
        <CSidebarNavItem v-if="modules.service_forms === 1 && checkPermission('service.forms.submissions')" name="Submissions" navItem="submissions" to="/service/forms/submissions" fontIcon="fa-solid fa-inbox" @click.native="toggleDropdown('service')"></CSidebarNavItem>
      </CSidebarNavDropdown>

      <!-- Insights -->
      <CSidebarNavItem v-if="company.payment_status === true && checkProductPermissions('insights')" name="Insights" navItem="insights" to="/insights" fontIcon="far fa-chart-bar" @click.native="toggleDropdown('insights')"></CSidebarNavItem>
      
      <!-- Core -->
      <CSidebarNavDropdown v-if="checkProductPermissions('core')" name="Core" navItem="core" fontIcon="fas fa-cog" :show.sync="dropdownOpenStatuses.core">
        <!-- <CSidebarNavItem v-if="checkPermission('core.license')" name="License" navItem="license" to="/core/license" fontIcon="fas fa-key" @click.native="toggleDropdown('core')"></CSidebarNavItem> -->
        <CSidebarNavItem v-if="checkPermission('core.organization.overview')" name="Organization" navItem="organization" to="/core/organization" fontIcon="fas fa-user-friends" @click.native="toggleDropdown('core')"></CSidebarNavItem>
        <!-- <CSidebarNavItem v-if="modules.routes === 1 && checkPermission('core.routes.overview')" name="Routes" navItem="routes" to="/core/routes" fontIcon="fas fa-gears" @click.native="toggleDropdown('core')"></CSidebarNavItem> -->
        <CSidebarNavItem v-if="checkPermission('core.targetgroups.overview')" name="Target groups" navItem="target-groups" to="/core/target-groups" fontIcon="fas fa-users-cog" @click.native="toggleDropdown('core')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('core.lookandfeel')" name="Look and Feel" navItem="look-and-feel" to="/core/look-and-feel" fontIcon="fas fa-palette" @click.native="toggleDropdown('core')"></CSidebarNavItem>
        <CSidebarNavItem v-if="checkPermission('core.systemcomms')" name="System Communications" navItem="system-communications" to="/core/system-communications" fontIcon="fas fa-bell" @click.native="toggleDropdown('core')"></CSidebarNavItem>
        <!-- <CSidebarNavItem v-if="checkPermission('core.emailtemplates')" name="Email Templates" navItem="templates" to="/core/email/templates" fontIcon="fas fa-clipboard" @click.native="toggleDropdown('core')"></CSidebarNavItem> -->
      </CSidebarNavDropdown>

      <!-- Super Admin -->
      <CSidebarNavItem v-if="checkRole('super_admin')" name="Admin" navItem="admin" to="/admin" fontIcon="fas fa-toolbox" @click.native="toggleDropdown('admin')"></CSidebarNavItem>
    </div>

    <CSidebarNavItem :name="$t('common.Employee_app')" fontIcon="fas fa-up-right-from-square" @click.native="openExperience()"></CSidebarNavItem>

  </CSidebarNav>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'TheSidebarMenu',
  components: {
    loadingSpinner
  },  
  data () {
    return {
      appBaseUrl: null,
      modules: {
        service: 0
      },
      modulesLoaded: false,
      platformPermissions: [],
      platformPermissionsLoaded: false,
      platformRoles: [],
      platformRolesLoaded: false,
      productLicenses: {
        loyalty_licence_tag: null
      },      
      company: {
        details: {},
        get_started_completed: false,
        look_and_feel: {},
        payment_status: false,
        payment_setup_completed: false,
        payment_type_tag: null
      },
      showMenu: false,
      showAllMenuItems: false,
      dropdownOpenStatuses: {
        connect: false,
        measure: false,
        knowledge: false,
        learn: false,
        loyalty: false,
        hub: false,
        todo: false,
        settings: false
      },
      interval: 300000,
      serviceUpdateInterval: null,
      serviceUpdatesAvailable: false,
      serviceUpdates: {
        new_service_questions: 0,
        unassigned_service_questions: 0,
        updated_service_questions: 0
      }
    }
  },
  methods: {
    toggleDropdown(item) {
      if(item !== 'connect' && this.dropdownOpenStatuses.connect === true) this.dropdownOpenStatuses.connect = false;
      if(item !== 'measure' && this.dropdownOpenStatuses.measure === true) this.dropdownOpenStatuses.measure = false;
      if(item !== 'knowledge' && this.dropdownOpenStatuses.knowledge === true) this.dropdownOpenStatuses.knowledge = false;
      if(item !== 'learn' && this.dropdownOpenStatuses.learn === true) this.dropdownOpenStatuses.learn = false;
      if(item !== 'loyalty' && this.dropdownOpenStatuses.loyalty === true) this.dropdownOpenStatuses.loyalty = false;
      if(item !== 'hub' && this.dropdownOpenStatuses.hub === true) this.dropdownOpenStatuses.hub = false;
      if(item !== 'core' && this.dropdownOpenStatuses.core === true) this.dropdownOpenStatuses.core = false;
    },
    getCompanyDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {      
        this.company = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkServiceQuestionUpdates() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/questions/updates')
      .then(res => {
        this.serviceUpdates = res.data.data;
        // Update the serviceUpdatesAvailable value if necessary
        if(this.serviceUpdates.new_service_questions > 0 || this.serviceUpdates.unassigned_service_questions > 0 || this.serviceUpdates.updated_service_questions > 0) {
          this.serviceUpdatesAvailable = true;
        } else {
          this.serviceUpdatesAvailable = false;
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    openExperience() {
      window.open(this.appBaseUrl, '_blank');
    },    
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
        // Set the showAllMenuItems value
        for(var r = 0; r < this.platformRoles.length; r++) {
          if(['loyalty'].includes(this.platformRoles[r])) {
            this.showAllMenuItems = false;
          } else {
            this.showAllMenuItems = true;
          }
        }
        // Update the platformRolesLoaded value        
        this.platformRolesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },       
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Check if the user has permissions for company details
        if(this.platformPermissions.includes('core.company.details')) {
          this.getCompanyDetails();
        }
        // Check if the Service module is activated and the user has permissions for service
        if(this.modules.service === 1 && this.platformPermissions.includes('service.center')) {
          // Check the service updates
          this.checkServiceQuestionUpdates();
          // Clear the interval if one is already running
          if(this.serviceUpdateInterval) clearInterval(this.serviceUpdateInterval);                  
          // Start an interval to keep checking the service updates every 5 minutes
          this.serviceUpdateInterval = setInterval(() => this.checkServiceQuestionUpdates(), this.interval);
        }
        // Update the platformPermissionsLoaded value        
        this.platformPermissionsLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {    
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkProductPermissions(productTag) {
      let hasProductPermission = false;      

      this.platformPermissions.find(permission => { 
        if(permission.includes(productTag)) {
          hasProductPermission = true;
        }

        if(productTag === 'core' && ['core.company.details'].includes(permission)) {
          hasProductPermission = false;
        }

        for(var r = 0; r < this.platformRoles.length; r++) {
          if(['core', 'insights'].includes(productTag) && ['loyalty', 'loyalty_benefits_moment_editor'].includes(this.platformRoles[r])) {
            hasProductPermission = false;  
          }
        }
      });

      return hasProductPermission;
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {
        this.modules = res.data.data;
        // Update the modulesLoaded value
        this.modulesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    }
  },
  created: function() {
    // Get the platform modules
    this.checkModules();
    // Wait 500ms and get the platform permissions and roles
    setTimeout(function() {      
      this.getPlatformPermissions();
      this.getPlatformRoles();
    }.bind(this), 500);
  },  
  async mounted() {
    this.appBaseUrl = process.env.VUE_APP_URL;
    this.productLicenses = await this.fetchProduuctLicenseData();
    
    this.$bus.$on('service_question_messages_read', (e) => {
      this.checkServiceQuestionUpdates();
    });

    this.$bus.$on('connect_chat_messages_read', (e) => {
      this.checkServiceQuestionUpdates();
    });

    this.$bus.$on('service_question_assigned', (e) => {
      this.checkServiceQuestionUpdates();
    });

    this.$bus.$on('module_status_updated', () => {      
      this.checkModules();
      // Wait 500ms and get the platform permissions and roles
      setTimeout(function() {      
        this.getPlatformPermissions();
      }.bind(this), 500);      
    })    
  },
  beforeDestroy() {
    this.$bus.$off('service_question_messages_read');
    this.$bus.$off('connect_chat_messages_read');
    this.$bus.$off('service_question_assigned');
    this.$bus.$off('module_status_updated');
  }  
}
</script>